<template>
    <div>
        <p>{{ name }}</p>
        <!-- TODO: show icon for comment, so user can tap it or hover to see comment, OR show first line of comment only with "..." to see more -->
        <!-- <p v-if="comment" class="text-italic">{{ comment }}</p> -->
        <!-- <p class="text-caption">{{ id }}</p> -->
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        name() {
            return this.attr.name;
        },
        comment() {
            return this.attr.comment;
        },
    },

};
</script>
