<template>
    <v-card class="pa-0">
        <v-app-bar color="teal" dark flat dense>
            <v-app-bar-title>Brands <template v-if="Array.isArray(list)">({{ list.length }})</template></v-app-bar-title>
            <v-spacer/>
            <!-- <v-btn icon @click="createNewItemDialog = true">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn> -->

            <v-btn icon :to="{ name: 'account-create-brand', params: { accountId: this.$route.params.accountId } }">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
        </v-app-bar>

            <!-- <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="green" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    < ! - - <v-tab v-on="on" class="green--text">
                        <Avatar :attr="mainProbrand" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                    </v-tab> - - >
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        < ! - - <v-list-item-content> - - >
                            <v-list-item-title>Settings</v-list-item-title>
                        < ! - - </v-list-item-content> - - >
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No brands yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <!-- <BrandListItem :attr="item" :link="!selectOne"></BrandListItem> -->
                    <BrandListItem :attr="item"></BrandListItem>
                </v-list-item-content>
                <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="green" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                        <!-- <v-tab v-on="on" class="green--text">
                            <Avatar :attr="mainProbrand" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                        </v-tab> -->
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'account-delete-brand', params: { accountId, brandId: item.id } }">
                            <!-- <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                            </v-list-item-icon> -->
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateBrand">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-template v-model="createBrand" ref="createBrandRef" @submit="validateCreateOrigin" onSubmit="return false;" @keyup.enter.native="validateCreateOrigin">
                    <div v-if="add">
                        <v-select :items="addBrandChoices" v-model="addBrandId" label="Select an template"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newOriginName
                            label="Display Name"
                            :rules="newOriginNameRules"
                            validate-on-blur
                            color="green"
                            required
                            hint="The name for the template"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addOrigin" :disabled="!createBrand" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createOrigin" :disabled="!createBrand" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-template>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateOriginDialog v-model="createOriginDialogVisible" @cancel="createOriginDialogVisible = false" @created="createOriginDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import BrandListItem from '@/components/list-item/BrandListItem.vue';
// import CreateOriginDialog from '@/components/account-dashboard/CreateOriginDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        BrandListItem,
        // CreateOriginDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the origin; maybe create a different component for "select a origin"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        // createOriginDialogVisible: false,
        // create origin
        displayCreateBrand: false,
        createBrand: null,
        newBrandName: null,
        newBrandNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Email origin name is required',
        ],
        // add origin
        addBrandChoices: [],
        addBrandId: null,
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            if (this.selectOne) {
                return 'Select a brand';
            }
            switch (this.list.length) {
            case 0:
                return 'No brands';
            case 1:
                return '1 brand';
            default:
                return `${this.list.length} brands`;
            }
        },
    },

    watch: {
        displayCreateBrand(value) {
            if (value && (this.add || this.create)) {
                this.initAddBrandChoices();
            }
        },
    },

    methods: {
        async loadBrandList() {
            try {
                this.$store.commit('loading', { loadBrandList: true });
                const match = {};
                if (typeof this.domain === 'string') {
                    match.domain = this.domain;
                }
                const response = await this.$client.account(this.$route.params.accountId).brand.search(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadBrandList failed', err);
            } finally {
                this.$store.commit('loading', { loadBrandList: false });
            }
        },
        onClickItem(id) {
            /*
            if (this.selectOne) {
                this.$emit('selected', { brandId });
            }
            */
            this.$emit('selected', { id });
        },
        // async createOrigin() {
        //     this.error = false;
        //     console.log('createOrigin');
        //     const request = {
        //         // name: this.name,
        //         name: this.newOriginName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if origin arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the origin to an appropriate location after origin is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).currentAccount.site.create(request);
        //     console.log('createOrigin response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const origin = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', origin);
        //         this.$emit('added-site', origin);
        //         this.displayCreateBrand = false;
        //         this.newOriginName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateOrigin() {
        //     if (this.$refs.createBrandRef.validate()) {
        //         this.createOrigin();
        //     }
        // },

        // async addOrigin() {
        //     const origin = { id: this.addBrandId, label: this.addBrandChoices.find((item) => item.value === this.addBrandId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', origin);
        //     this.displayCreateBrand = false;
        //     this.addBrandId = null;
        // },
        // async initAddOriginChoices() {
        //     // create a map of which origin ids are already in the list
        //     const originMap = {};
        //     this.list.forEach((item) => {
        //         originMap[item.id] = true;
        //     });
        //     // when we show the add/create origin dialog, load the list of available origins to add to the policy (list of all origins less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).currentAccount.site.list();
        //     console.log(`OriginViewList.vue fetch origins result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove origins already in the policy, then convert the available origins to a choice list for the v-select [ { text, value }, ... ]
        //         this.addBrandChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadBrandList();
    },
};
</script>
