<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ accountName }}</h1>
                <p class="text-caption text-center">Brands</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8 mt-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <BrandList @selected="onSelectBrand"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import BrandList from '@/components/account-dashboard/BrandList.vue';

export default {
    components: {
        BrandList,
    },
    data: () => ({
        error: null,
    }),
    computed: {
        ...mapState({
            account: (state) => state.account,
        }),
    },
    methods: {
        onSelectBrand({ id }) {
            this.$router.push({ name: 'account-edit-brand', params: { accountId: this.$route.params.accountId, brandId: id } });
        },
    },
};
</script>
